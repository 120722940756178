.userAccount {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin: 0px 10px;
  color: #7c7c7c;

  span {
    &:nth-child(2) {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 900px) {
    align-items: flex-start;
  }
}
