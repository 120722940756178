@import "../styles/index.scss";

.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: stretch;

  .homeContent {
    display: flex;
    flex-grow: 1;
    align-self: stretch;

    .homeContainer {
      flex-grow: 1;
      width: 100%;
    }
  }
  .bottom_navigator {
    display: flex;

    .nav {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      padding: 6px;
      align-items: center;
      justify-content: center;
      color: $gray-dark;
      font-size: 12px;
    }

    .active {
      color: $primary-color;
    }
  }
}
