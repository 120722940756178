.progress_bar {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;

  .bar {
    background-color: #eeeeee;
    border-radius: 4px;
    width: 100%;
    height: 8px;
    display: inline-block;

    .value {
      background-color: var(--primary-color);
      transition: 0.3s all linear;
      border-radius: 4px;
      height: 8px;
      margin-bottom: 12px;
      display: inline-block;
      animation: all 3s ease-in-out;
    }
  }

  .label {
    display: flex;
    font-size: 14px;
    width: 100%;
    justify-content: space-between;
  }
}
