.logoContainer {
  display: flex;
  align-items: center;

  .actionButton {
    margin: 15px;
  }

  .logo {
    display: flex;
    margin: 0px 10px;

    .pracsite {
      display: flex;

      img {
        width: 125px;
      }
    }
  }
}
