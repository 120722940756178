.navWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  background-color: #f2f2f2;
  padding: 10px 0px;
  z-index: 999;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .contentContainer {
    display: flex;
    flex-grow: 1;
  }
  .breadCrumb {
    display: flex;
    width: 85%;
    justify-content: space-evenly;
    align-items: center;

    .active {
      font-weight: bold;
    }
    @media only screen and (max-width: 960px) {
      display: none;
    }
  }

  .org_logo {
    max-height: 35px;
    margin-right: 10px;
    height: 100%;

    img {
      height: 100%;
      width: auto;
    }
    @media only screen and (max-width: 500px) {
      max-height: 32px;
    }
  }

  .dropdown {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #8e8e8e;
  }
}
